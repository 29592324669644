:root {
    --palette-color1: #ffffff;
    --palette-color2: #FFFFFF; 
    --palette-color3: #192153;// Header color
    --palette-color4: #161a21;
    --palette-color5: #63667B;
    --palette-color6: #bdbdbd2e;
    --palette-color7: #dc3545;
 

    //--c1: #6D4444;//--palette-color4 --c1: #F8CCCC;//--palette-color5 --c1: #414352; --c1: #FEAEAE;//--palette-color2 --c1: #3E0699;//--palette-color3 --c1: #F6C7C7;//--palette-color1 --c1: #4A4A4A; --c1: #CCAEAE; --c1: #f8e0e0;
  }

// $palette-color1: #161a21;// $palette-color2: #111419;// $palette-color3: #13ECA4;// $palette-color4: #414352;// $palette-color5: #63667B;
$palette-color1: var(--palette-color1);
$palette-color2: var(--palette-color2);
$palette-color3: var(--palette-color3);
$palette-color4: var(--palette-color4);
$palette-color5: var(--palette-color5);
$palette-color6: var(--palette-color6);
$palette-color7: var(--palette-color7);


* {
    margin: 0;
}

body {
    background: $palette-color1 !important;
}

body .font-roboto {
    font-family: 'Roboto', sans-serif !important;
}

body .font-museo-Sans {
    font-family: 'MuseoModerno', cursive !important;
}

body .font-open-sans {
    font-family: 'Open Sans', sans-serif !important;
}

body .font-lato {
    font-family: 'Lato', sans-serif !important;
}

.head-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
}

.head-text span {
    color:  $palette-color6 !important;
}


.color-green {
    color: $palette-color3 !important;
}

.head-text {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 29px !important;
    margin-bottom: 0px !important;
}

.d-none7 {
    padding-right: 0px !important;
}

.d-none7 .desktop-nav-icon {
    display: none;
}

.d-none7 svg {
    margin-right: 0px !important;
}

.d-none7 .responsive-nav-icon {
    display: block;
}

.d-none7 span {
    display: none;
}

a:not([href]):not([tabindex]) {
    color: none !important;
}

a:focus {
    outline: none;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

.font-top {
    padding-top: 10px;
    padding-left: 40px;
    margin-bottom: 0px !important;
}

button:hover {
    outline: none;
}

.dashboard-body {
    padding-top: 1%;
    background-color: $palette-color1 !important;
    // padding-left: 13%;
    width: 100%;
    position: relative;
}

.d-none2 {
    padding-left: 150px !important;
}

/* header css */

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 5px;
}

::-webkit-scrollbar:horizontal {
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #595B68;
    border-radius: 20px;
    border: 0px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 20px;
}

/*top header css start*/

.navbar-nav li:first-child {
    width: 350px;
}

.navbar-nav li {
    padding: 0 15px;
}

.navbar-nav li:hover svg path {
    fill: #13ECA4;
}

.navbar-nav li:hover svg .setting-stroke {
    fill: none;
    stroke: $palette-color2;
}

.navbar-nav li:hover svg .search-line {
    stroke: #13ECA4;
}

.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
}

.navbar-brand {
    margin-left: 20px;
}

.d-none3 {
    margin-left: 90px !important;
}

.d-none {
    display: none !important;
}

.top-header {
    background: $palette-color2;
    padding: 15px 20px;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 999999;
}

.message-notification {
    position: relative;
}

.responsive-nav-icon {
    display: none;
}

.message-dot {
    position: absolute;
    width: 14px;
    height: 13.58px;
    border-radius: 50px;
    right: 12px;
    top: 12px;
    background: rgba(255, 93, 93, 1);
}

.btn-language {
    border-radius: 50px;
    border: 1px solid #414352;
    padding: 5px 5px;
    color: #fff;
    width: 220px;
    text-align: left;
    background: $palette-color2;
}

.video-right {
    width: 100%;
    padding: 20px 0;
    box-shadow: -5px -5px 15px, 5px 5px 15px$palette-color2;
    border-radius: 10px;
}

.video-right ul.camera-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}

.video-right ul.camera-list li {
    width: 100%;
    padding: 0 10px;
}

.btn-language img {
    margin-right: 25px;
}

.dropdown-toggle.btn-language::after {
    border: none;
    // background: url(assets/images/downarrow.png) no-repeat center right;
    width: 20px;
    height: 13px;
    margin-left: 2.8em;
    vertical-align: middle;
}

.dropdown-toggle.btn-language:hover {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-country {
    border-radius: 50px;
    border: 1px solid #414352;
    padding: 5px 5px;
    color: #fff;
    width: 270px;
    text-align: left;
    background: $palette-color2;
}

.btn-country img {
    margin-right: 25px;
}

.dropdown-toggle.btn-country::after {
    border: none;
    // background: url(./assets/images/downarrow.png) no-repeat center right;
    width: 20px;
    height: 13px;
    margin-left: 1.9em;
    vertical-align: middle;
}

.dropdown-toggle.btn-country:hover {
    box-shadow: none;
    outline: none;
    color: #fff;
}

ul.notification-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.list-icon img {
    width: 100%;
    height: 30px;
}

.list-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.list-col {
    display: flex;
    align-items: center;
}

.list-top h5 {
    margin: 0px;
    color: #FFD740;
    font-size: 14px;
}

.list-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.list-bottom p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}

.bottom-line {
    width: 100%;
    background: #63657b;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-col {
    width: 72px;
    height: 18px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
}

.info-btn {
    background: #4CB050;
}

.warning-btn {
    background: #FF5353;
}

.error-btn {
    background: #FF9700;
}

.time {
    color: #63667B;
    font-size: 16px;
    margin-left: 30px;
    font-weight: bold;
}

.time img {
    margin-right: 5px;
}

.list-icon {
    width: 30px;
    margin-right: 10px;
}

ul.notification-list li {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

ul.notification-list li:nth-child(even) {
    background: #1b2028;
}

ul.notification-list li:nth-child(odd) {
    background: $palette-color2;
}

.view-all-btn {
    width: 100%;
    background: #1b2028;
    height: 50px;
    display: flex;
    align-items: center;
    color: #fff !important;
    text-transform: uppercase;
    justify-content: center;
}

.fade:not(.show) {
    opacity: 1;
}

.sort-col {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.sort-col p {
    color: #fff;
    margin: 0 5px 0 0;
}

.clear-btn {
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #63667B;
    border-radius: 5px;
    color: #fff !important;
    font-size: 12px;
}

.clear-btn svg {
    margin-right: 5px;
}


.noti-right {
    display: flex;
    align-items: center;
}

.side-bar {
    width: auto;
    background: $palette-color2;
    position: fixed;
    left: 0px;
    top: 90px;
    bottom: 0px;
    z-index: 99999;
}

.top-header .navbar-nav {
    flex-direction: row;
}


/* expand search */

.screen-reader-text {
    height: auto;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
}

.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    height: auto;
    width: auto;
    background-color: #000000;
    border-radius: 2px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #FFFFFF;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    left: 0.3125rem;
    line-height: normal;
    padding: 0.9375rem 1.4375rem 0.875rem;
    text-decoration: none;
    top: 0.3125rem;
    z-index: 100000;
}

.search-form {
    display: inline-block;
    float: right;
    position: relative;
    width: 100%;
}

.search-field, .search-submit {
    height: 3.75rem;
}

.search-field {
    position: absolute;
    right: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
    box-sizing: border-box;
    color: $palette-color4;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    opacity: 0;
    padding-right: 3.75rem;
    width: 0;
    z-index: 3;
}

.search-field:focus {
    cursor: text;
    opacity: 1;
    outline: none;
    width: 100%;
    z-index: 1;
}

.search-field:focus:hover~.search-submit .fa-search {
    color: #FFFFFF;
}

.search-field:hover~.search-submit .fa-search {
    color: white;
}

.search-submit {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    border: none;
    float: right;
    position: relative;
    width: 3.75rem;
    z-index: 2;
}

.search-submit:hover .fa-search {
    color: white;
}

.fa-search {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #FFFFFF;
    font-size: 2.25rem;
}

.time-li {
    padding: 0 !important;
}

.time {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: $palette-color4;
    margin-bottom: 0;
}

.notification-col ul.nav-tabs li a.nav-link:before {
    background-image: none;
}

.notification-col .nav-tabs .nav-item.show .nav-link, .notification-col .nav-tabs .nav-link.active {
    background: $palette-color1;
    color: #fff;
}

.notification-col .nav-tabs .nav-link {
    height: 40px;
    border-radius: 0px;
    box-shadow: none;
}

.notification-col .nav-tabs .nav-link.active {
    box-shadow: none;
}

.notification-col .nav-tabs li:nth-child(1) .nav-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.notification-col .nav-tabs li:nth-child(2) .nav-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.notification-col .nav-tabs {
    height: 40px;
    border-radius: 7px;
    position: relative;
}

.notification-col .nav-tabs:before {
    width: 0;
    height: 0;
    border-bottom: 20px solid $palette-color5;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    top: -10px;
    content: "";
    left: 55%;
}

.notification-col .nav-tabs .nav-link {
    background: $palette-color5;
    border: 1px $palette-color5 solid;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noti-top-col {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    
}

.noti-btn {
    border: 1px rgb(212, 213, 224) solid;
    border-radius: 30px;
    padding: 2px 10px;
    font-size: 11px;
    margin-left: 20px;
}
ul.nav-tabs li a ul.nav-tabs li a .noti-btn {
    color: #fff;
    border-radius: 40px;
    font-size: 12px;
    padding: 2px 5px;
    border: 1px #fff solid;
}

.notification-col .nav-tabs .nav-item.show .nav-link, .notification-col .nav-tabs .nav-link.active {
    background: $palette-color1;
    color: #fff;
}

#sidebar {
    height: 100vh;
    overflow-y: auto;
    width: 100%;
}

#sidebar ul.nav {
    width: 100%;
}

#sidebar ul li {
    margin-bottom: 5px;
    padding: 5px 15px 5px 30px;
}

#sidebar ul li a:hover svg path {
    fill: $palette-color3;
}

#sidebar ul li svg {
    height: 22px;
}

#sidebar ul li a:hover svg .black-icon {
    fill: #1D1F22;
}
#sidebar ul li a svg .black-icon {
    fill: #1D1F22;
}

#sidebar ul li a:hover svg ellipse {
    fill: $palette-color3;
}

#sidebar ul li a:hover {
    color: $palette-color3;
}

#sidebar ul li:hover .nav-link[data-toggle]:not(.collapsed):before {
    // background: url(assets/images/hover-down-arrow.svg) no-repeat center right;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    z-index: 999;
}

#sidebar ul li:hover .nav-link[data-toggle].collapsed:before {
    // background: url(assets/images/hover-right-arrow.svg) no-repeat center right;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    z-index: 999;
}

#sidebar ul li a {
    display: flex;
    align-items: center;
    color: $palette-color4;
}
#sidebar ul li a svg path{
    fill: $palette-color4;
}

#sidebar ul li a svg {
    margin-right: 20px;
}

#sidebar ul li.active {
    background: #1D1F22;
}

#sidebar ul li.active a {
    color: $palette-color3;
}

#sidebar ul li.active {
    border-right: 4px $palette-color3 solid;
}

#sidebar ul li.active a svg .das-icon {
    fill: $palette-color3;
}

#submenu2, #submenu1 {
    margin-top: 20px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
    box-shadow: -5px -5px 15px, 5px 5px 15px #08090b;
    background: $palette-color1;
    border-color: none;
    font-size: 18px;
    border-radius: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 160px;
    height: 48px;
    color: #242629;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active span{ color: #fff !important;}
.nav-tabs .nav-link span {
    color: $palette-color5 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: transparent;
}
.widget-dropdown ul li.active { 
    background: #000;
}
ul.horizantal-nav .nav-link[data-toggle].collapsed:before {
    content: "";
    // background: url(assets/images/down-arrow.svg) no-repeat center center;
    top: 15px;
    position: absolute;
    right: 20px;
}

ul.horizantal-nav .nav-link[data-toggle]:not(.collapsed):before {
    // background: url(assets/images/down-arrow.svg) no-repeat center right;
    right: 20px;
    content: "";
    top: 15px;
    position: absolute;
}
.nav-link[data-toggle].collapsed:before {
    content: "";
    // background: url(assets/images/right-arrow.svg) no-repeat center right;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    z-index: 999;
}
.nav-link {
    height: 45px;
    width: 100%;
    position: relative;
}

.nav-link[data-toggle]:not(.collapsed):before {
    content: "";
    // background: url(assets/images/down-arrow.svg) no-repeat center right;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    z-index: 999;
}



/* horizaltal nav */

ul.horizantal-nav {
    margin-top: 90px;
    width: 100%;
    background: $palette-color2;
    position: fixed;
    z-index: 9999999;
    padding: 0 0 15px;
}

ul.horizantal-nav li {
    width: 10%;
    display: flex;
    align-items: center;
}

ul.horizantal-nav li a {
    display: flex;
    color: $palette-color4 !important;
}
ul.horizantal-nav li a svg path {
    fill: $palette-color4;
}
ul.horizantal-nav li a:hover svg .black-icon {
    fill: #1D1F22 !important;
}
ul.horizantal-nav li a svg .black-icon {
    fill: #1D1F22 !important;
}
ul.horizantal-nav li a:hover {
    color: $palette-color3 !important
}

ul.horizantal-nav li.active a svg .das-icon {
    fill: $palette-color3;
}

ul.horizantal-nav li.active a {
    color: $palette-color3 !important;
}

ul.horizantal-nav li a:hover svg ellipse {
    fill: $palette-color3;
}

ul.horizantal-nav li:not(.active) a:hover svg path {
    fill: $palette-color3 !important;
}

ul.horizantal-nav li a svg {
    margin-right: 20px;
}

ul.horizantal-nav li #submenu1 {
    position: absolute;
    top: 40px;
    background: $palette-color2;
}

ul.horizantal-nav li #submenu2 {
    position: absolute;
    top: 40px;
    background: $palette-color2;
}

.collapse ul li {
    width: 100%;
}

.collapse ul li a {
    display: flex;
    align-items: center;
}


.setting-col {
    width: 330px;
    position: fixed;
    top: 75px;
    right: 98px;
    background: rgba(17, 20, 25, 1);
    z-index: 9999999999;
    border-radius: 5px;
}

.setting-col:before {
    width: 0;
    height: 0;
    border-bottom: 20px solid $palette-color5;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    top: -10px;
    content: "";
    left: 80%;
}


/* radio */

.mode h2 {
    width: 60px;
}

.mode .radio {
    padding-left: 50px;
}

.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 15px;
    color: #DADCE7;
    margin: 0px;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-left: -20px;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
    // background: url(assets/images/radio-btn.svg) no-repeat center center;
    top: 3px;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 18px;
    height: 18px;
    left: 0px;
    top: 4px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    position: absolute;
}

.radio input[type="radio"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.radio input[type="radio"]:checked+label::after {
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    // background: url(assets/images/radio-check.svg) no-repeat center center;
}

.radio input[type="radio"]:disabled+label {
    opacity: 0.65;
}

.radio input[type="radio"]:disabled+label::before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}

.white-text {
    color: black;
    margin: 0px 0px 0px 0px;
    font-size: 12px;
  }
 .white-text-new{
    color:white;
    margin: 0px 10px 0px 0px;
    font-size: 12px;  
 }
 .icon{
    color:white;
    display: inline;
    float: left;
    margin: 0px 5px 0px 0px;
 }

.radio-primary input[type="radio"]+label::after {
    background-color: #428bca;
}

.radio-primary input[type="radio"]:checked+label::before {}

.radio-primary input[type="radio"]:checked+label::after {}

/* setting */

.color-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -15px;
    display: flex;
}
.top-col {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom:20px;
}

.color-list {
    display: flex;
    margin-left: 40px;
    margin-top: 15px;
}


.theme-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.theme-list .theme-box {
    width: 37px;
    height: 31px;
    background: $palette-color5;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.theme-list .theme-box:nth-child(6) {
    margin-right: 0px;
}

.theme-list .theme-box:nth-child(12) {
    margin-right: 0px;
}

.theme-list-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $palette-color5;
    font-size: 14px;
    margin-bottom: 20px;
}

.theme-list-top h4 {
    margin: 0px;
    font-size: 14px;
}

.theme-list {
    justify-content: space-between;
}

.setting-col ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.setting-col ul li {
    width: 100%;
    border-bottom: 1px $palette-color5 solid;
    padding: 5px 0;
}

.setting-top {
    background: $palette-color5;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.setting-top h3 {
    font-size: 18px;
    color: #DADCE7;
    margin: 0px;
}

.mode {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
}

.mode2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
}

.mode h2 {
    margin: 0px;
    color: $palette-color5;
    font-size: 14px;
}

.currency {
    width: 100%;
    border: 1px solid $palette-color5;
    border-radius: 40px;
    height: 48px;
    padding: 0 6px 0 60px !important;
    font-size: 18px;
    color: $palette-color5;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    // background: url(assets/images/select-dropdown.png);
    /* For Chrome and Safari */
    // background: url(assets/images/select-dropdown.png);
    /* For old Fx (3.6 to 15) */
    // background: url(assets/images/select-dropdown.png);
    /* For pre-releases of IE 10*/
    // background: url(assets/images/select-dropdown.png);
    /* For old Opera (11.1 to 12.0) */
    // background: url(assets/images/select-dropdown.png);
    /* Standard syntax; must be last */
    background-position: right 15px;
    background-repeat: no-repeat;
    overflow: hidden;
}

.setting-btn {
    color: #DADCE7 !important;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.currency-img {
    position: absolute;
    left: 43px;
    top: 13px;
}

.currency-col {
    width: 100%;
    padding: 10px 40px;
    position: relative;
}

/* notification css */

.notification-col {
    width: 550px;
    position: fixed;
    right: 44px;
    top: 75px;
    background: $palette-color1;
    z-index: 999999999;
}

ul.nav-tabs {
    display: flex;
}

.dashboard-box-list ul.nav-tabs li {
    width: auto;
}

.dashboard-box-list .nav-link[data-toggle]:not(.collapsed)::before {
    background: none;
}

ul.nav-tabs li {
    width: 50%;
}

ul.nav-tabs li a {
    color: #fff;
    text-align: center;
}

.domain-list {
    position: absolute;
    width: 180px;
    left: 0px;
    background: #63667B;
    border-radius: 8px;
}

.domain-list ul li a {
    height: auto;
    padding: 5px 10px !important;
    border-bottom: 1px $palette-color2 solid;
    font-size: 14px;
    color: #fff;
}

.domain-list ul li:last-child a {
    border-bottom: none;
}

.domain-list {
    font-size: 14px;
}

.domain-list ul li:last-child a:hover {
    color: #13ECA4 !important;
}

.dropdown-text {
    background: #63667B;
    border-radius: 8px;
    color: #fff;
    width: 180px;
    padding: 5px 10px !important;
    height: auto;
}

.dropdown-text:hover {
    color: #fff !important;
}

.domain-list ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}



.modal-backdrop.fade.show {
    opacity: 0 !important;
}
.fade:not(.show) {
    opacity: 1;
}
.dropdown .dropdown-text[data-toggle].collapsed:before {
    // background: url(assets/images/white-down-arrow.svg) no-repeat center right;
    right: 10px !important;
    content: "";
}

.dropdown .dropdown-text[data-toggle]:not(.collapsed):before {
    // background: url(assets/images/white-down-arrow.svg) no-repeat center right;
    right: 10px !important;
    content: "";
}
.dropdown-text {
    background: $palette-color5;
    border-radius: 8px;
    color: #fff;
    width: 180px;
    padding: 5px 10px !important;
    height: auto;
}

.dropdown-text:hover {
    color: #fff !important;
}


.domain-list {
    position: absolute;
    width: 180px;
    left: 0px;
    background: $palette-color5;
    border-radius: 8px;
}

.domain-list ul li a {
    height: auto;
    padding: 5px 10px !important;
    border-bottom: 1px $palette-color2 solid;
    font-size: 14px;
    color: #fff;
}

.domain-list ul li:last-child a {
    border-bottom: none;
}

.domain-list {
    font-size: 14px;
}

.domain-list ul li:last-child a:hover {
    color: $palette-color3 !important;
}
.domain-list ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.sort-col {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.sort-col p {
    color: #fff;
    margin: 0 5px 0 0;
}


// Innoart

.palette-color3 {
    color: $palette-color3 !important;
}
.palette-color4 {
    color: $palette-color4 !important;
}

.mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1 !important;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    color: $palette-color3 !important;
}

@media (min-width: 1199px) and (max-width:1450px) {
    .top-header {
        padding: 10px 20px;
    }
    .time {
        font-size: 16px;
    }
    .nav-link {
        height: 40px;
    }
    .setting-col {
        right: 88px;
    }
    .notification-col {
        right: 33px;
    }
    .head-text {
        font-size: 18px;
    }
    .user img {
        height: 40px;
    }
    .setting img, .squ-icon img {
        height: 25px;
    }
    .info img {
        height: 22px;
    }
    .message-notification img {
        height: 22px;
    }
    .flag img {
        height: 30px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .setting-col {
        right: 100px;
    }
    .notification-col {
        width: 500px;
        right: 65px;
    }
    .head-text {
        font-size: 20px;
    }
}

@media (min-width:320px) and (max-width:1024px) {
    .top-header {
        padding: 10px 0;
    }
}
@media all and (min-width: 320px) and (max-width: 575px) {
    .dropdown-text {
        width: 85px;
        font-size: 14px;
    }
    .dropdown .dropdown-text[data-toggle].collapsed:before {
        top: 5px;
    }
}
@media all and (min-width: 768px) and (max-width: 991px) {
    .setting-col {
        right: 70px;
    }
    .head-text {
        font-size: 18px;
    }
}
@media all and (min-width: 768px) and (max-width: 1024px) {
    .time {
        margin-left: 0;
    }
    .setting-col {
        right: 70px;
    }
    
    .notification-col {
        width: 485px;
        right: 32px;
    }
}
@media all and (min-width: 320px) and (max-width: 767px) {
    .time {
        display: none !important;
    }
    .setting-col {
        width: 302px;
        top: 76px;
        right: 13%;
    }
    .currency {
        font-size: 14px;
    }
    .notification-col {
        width: 100%;
        right: 0;
        padding: 0 5px;
        top: 70px;
    }
    .notification-col .nav-tabs:before {
        left: 58%;
    }
    .notification-col .nav-tabs .nav-link {
        font-size: 14px;
    }
    .top-col {
        align-items: center;
    }
    .head-text {
        font-size: 20px;
    }
    .user img {
        width: 28px;
        height: 28px;
    }
    .info {
        display: none !important;
    }
    .time {
        display: none !important;
    }
    .time-li {
        padding: 0 !important;
    }
    .currency {
        font-size: 14px;
    }
    .setting-col {
        width: 302px;
        top: 76px;
        right: 13%;
    }
    .setting-btn {
        font-size: 14px;
    }
    .squ-icon {
        display: flex;
        align-items: center;
    }
    .squ-icon svg {
        width: 25px;
        height: 25px;
    }
    .nav-item.setting svg {
        width: 25px;
        height: 25px;
    }
    .nav-item svg {
        width: 30px;
        height: 30px;
    }
    .nav-item .search-icon svg {
        width: 22px;
        height: 22px;
    }
}


.mat-stepper-horizontal {
    margin-top: 8px;
  }
  
//   .mat-form-field {
//     margin-top: 2px;
//    display: contents !important; 
//   }

//   .mat-form-field-right{
//     margin-left: -16px;
//   }



//Mat Input
.input{
    border: 1px solid $palette-color4 !important;
    color: $palette-color3 !important;
    padding: 1% !important;
    border-radius: 6px;
    margin-top: 1% !important;
}


  .next_btn_div{
    text-align: right;
    margin-top: 10px;
  }


.btn_left{
    margin-right: 5px !important;
}





// datatable 
.ngx-datatable,
.info {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  font-family: 'Arial,Sans-Serif';
  border-radius: 25px;
}

.info {
  box-sizing: border-box;
  padding: 0 1em;
  border: solid 1px #ccc;
  background: white;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.datatable-icon-right:before {
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-skip:before {
  font-family: 'icofont';
  font-style: normal;
}

.datatable-icon-left:before {
  font-family: 'icofont';
  font-style: normal;
}
:host {
  display: block;
  width: 100%;
  height: 100%;  
}

.ngx-datatable.scroll-vertical {
  height: 70vh;
  background-color: $palette-color1 !important;
}

.server-scrolling-demo {
    height: calc(100vh - 110px);
  }
     
   .progress-linear {
    position: fixed !important;
    bottom: 0px;
  }
   .ngx-datatable.material{
    background: $palette-color1 !important;
  }
  
  /* data table header alignment */
  .ngx-datatable .datatable-header {
    background:$palette-color1 !important;
    color: $palette-color3 !important;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
  }
  
  /* datatable body of the content style */
   .ngx-datatable .datatable-body {
    background: $palette-color1 !important;
    font-size: 14px !important;
    color: $palette-color4 !important;
   
  }
  
  .ngx-datatable .datatable-body .datatable-body-row {
    padding: 0.75rem;
    vertical-align: top;
    border-top : 0.5px solid lightgray;
 }
  
  /* data table footer styles */
  .ngx-datatable .datatable-footer .datatable-footer-inner {
    background: $palette-color1 !important;
  }
  
  .ngx-datatable .datatable-footer .page-count {
    margin-left: 10px;
    color: $palette-color4 !important;
  }
  
  .ngx-datatable .datatable-footer .datatable-pager {
    margin-right: 25px;
  }
  
  /* data table inner header alignment */
  .datatable-header-inner
  {
    padding-left: 0.3%;
  }
  /* data table header cell alignment */
  .datatable-header-cell-label
  {
   
    text-align: left;
    justify-content: left;
  }
  /* data table body content */
  .datatable-body
  {
    padding-left:0%;
  }
  /* ::ng-deep .datatable-body-row */
  .datatable-body-cell-label
  {
    text-align: left;
    padding-left:3%;
    justify-content: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  
  }
  /* datatable sno alignment */
  .sno-style
  {
    text-align: center!important  ;
  }
  
// datatable footer design
  .ngx-datatable .datatable-footer .datatable-pager {
    flex: 1 1 -1% !important;
    text-align: right;
    border-radius: 10px;
  }

  
  .ngx-datatable .datatable-footer .datatable-pager .pager{
    padding: 1px !important;
    background: rgb(92, 90, 90) !important;
    border-radius: 10px !important;
  }
  
  .ngx-datatable .datatable-footer .datatable-pager .pager li a {
    text-decoration: none !important;
    color: $palette-color4 !important;;
  }
  .ngx-datatable .datatable-footer .datatable-pager .pager li {
    padding-left: 5px;
    padding-right: 5px !important;
    
  }
  .ngx-datatable .datatable-footer .datatable-pager .pager .active  a {
    // background: #EFAA10;
    color: $palette-color3 !important;;
  }
  
.ngx-datatable .datatable-footer .page-count {
    display: none !important;
    
  }
  .iconbutton
  {
    //   border:none !important;
    //   background: none !important;
    //   height:0px !important;
    //   line-height: 22px !important;
    padding: 0px;
  }


  .orglist_tableiconsize
  {
    text-align: center;
    justify-content: center;
    width:50%;
  }


  .details_header{
      color: $palette-color3;
      margin-bottom: 5px;
  }
  

  .div_form_body{
    background-color: $palette-color1 !important;
    //border: 1px solid darkslategray;
    padding: 10px 0px;
  }

.service_savecancel
{
    margin-top: 5%;
    text-align: center;
    justify-content: flex-end;
}



 .link{
    text-decoration: underline !important;
    text-decoration-color: $palette-color3 !important;
    cursor: pointer;
 }

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.div_dept{
    padding: 10px;
    box-shadow: 0 0 3px 3px grey;
    
}

.div_content{
    color: $palette-color4 !important;
}

.div_top{
    margin-top:10px
}

.mat-hint{
    visibility: hidden;
}

//Murugeswari
.div-remove-btn
{
    justify-content: flex-end !important;
}
.remove-btn
{
    border-radius: 50px !important;
    /* box-shadow: 0px 0px 2px 3px #f44336; */
    background: $palette-color5 !important;
    border:  $palette-color5 !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    padding: 1px 5px !important;
    font-size: 10px !important;
    // margin-top: -17px;
    margin-right: -5px;
}
.remove-btn:hover
{
    background: red !important;
    border:  red !important;
}
.add-btn
{
    border-radius: 50px !important;
    /* box-shadow: 0px 0px 2px 3px #f44336; */
     background: $palette-color3 !important;
    border:  $palette-color3 !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    padding: 2px 8px !important;
    font-size: 10px !important;
    margin-left: 33px !important;
    // margin-top: 10px;
}
.cls-large-btn
{
    border-radius: 50px !important;
    /* box-shadow: 0px 0px 2px 3px #f44336; */
    // background: $palette-color7 !important;
    // border:  $palette-color7 !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    padding: 6px 12px !important;
    font-size: 10px !important;
    margin-left: 33px !important;
    // margin-top: 10px;
}
.custom-cls-repeat
{
    width: 100px !important;
}
.custom-cls-input
{
    width: 100px !important;
}
.cls-crd formly-group
{
    display: flex;
    //  flex-direction: column;
    // flex-grow:f;
}
.conjuction-txt
{
    font-size: 14px;
    padding: 10px;
}
.custom-flex > formly-group
{   flex-direction:column !important ;
     padding:15px;border:1px solid lightgray;margin-bottom:20px; 
}
.custom-cls-repeat-div{
    padding:10px;border:1px solid lightgray;margin-bottom:20px; 
}

.error_msg
{

    position: absolute;
    color:  red !important;
    top: 50px;
}

.cls-crd{
    
    margin-top:3px !important;
}
.cls-crd mat-form-field
{
    margin-right: 5px !important;
}
.custom-width 
{
    width: 500px !important;
}

//side menu design


// .repeat-component .mat-form-field-appearance-outline .mat-form-field-infix {
//     padding: 1em 0 1em 0 !important;
//   //  width: 200px !important;
//     vertical-align: middle !important;
//     height: 35px !important;


//     border-top:unset !important;
// }
// .repeat-component .mat-form-field-wrapper
// {
//     padding-bottom: unset !important;
// }
// .repeat-component .mat-form-field-appearance-outline .mat-form-field-wrapper {
//     margin: 1em 0 !important;
// }
.repeat-component{
    margin-bottom: 10px;
     padding: 10px 0px;
    // border: 1px solid lightgray !important;
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
    //  width: 960px !important;
}
.repeat-header-div
{
    margin: 20px 0px 20px 0px;
    
}
.repeat-header-txt
{
    font-size: 18px;
    
}
.custom-repeat{
min-width: 1000px;
padding: 0px 15px;
background:$palette-color6;
}
.apply-btn
{
    margin:10px !important;
    color:var(--palette-color4);
    background-color:var(--palette-color1)
}

.renewal-btn
{
    margin:10px !important;
    // color:var(--palette-color4);
    // background-color:var(--palette-color3)
}
.upgrade-btn
{
    margin:10px !important;
    // color:var(--palette-color4);
    // background-color:var(--palette-color7)
}
.cls-btn-parent
{
text-align: end;
justify-content: flex-end;
}

.slct-hrs .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    background:$palette-color1 !important;
    box-shadow: 0px 0px 8px 0px $palette-color7;
    border-radius: 3px;
    color: $palette-color1 !important;
}
.slct-hrs .mat-form-field-appearance-outline .mat-form-field-outline {
    background:$palette-color1 !important;
    box-shadow: 0px 0px 8px 0px $palette-color7;
    border-radius: 3px;
    color:$palette-color1 !important;
}

.slct-hrs .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    background:$palette-color1 !important;
    box-shadow: 0px 0px 8px 0px $palette-color7;
    border-radius: 3px;
    color: $palette-color1 !important;
}
.slct-hrs .mat-form-field-appearance-outline .mat-form-field-outline {
    background:$palette-color1 !important;
    box-shadow: 0px 0px 8px 0px $palette-color7;
    border-radius: 3px;
    color:$palette-color1 !important;
}

.slct-hrs .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border-color: $palette-color5 !important;
}

.slct-hrs .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: $palette-color1 !important;
}
.cls-datepicker
{
    margin-top: 5px;
    padding: 7px;

    border: 1px solid rgba(0,0,0,.12);
    border-radius: 4px !important;
}
.cls-custom-datepicker
{
    margin-top: 5px;
    padding: 6px;
    border: 1px solid #d2cfcf;
    border-radius: 4px;
}
.cls-custom-datepicker .e-input-group, .cls-custom-datepicker .e-input-group:focus, .cls-custom-datepicker .e-input-group:focus-visible,.cls-custom-datepicker .e-input-group:focus-within,.cls-custom-datepicker .e-input-group:hover,.cls-custom-datepicker .e-input-group:target,.cls-custom-datepicker .e-input-group:active,.cls-custom-datepicker .e-input-group:visited
{
    border: none !important;
}
.slct-hrs .e-input-group, .slct-hrs .e-input-group:focus, .slct-hrs .e-input-group:focus-visible,.slct-hrs .e-input-group:focus-within,.slct-hrs .e-input-group:hover,.slct-hrs .e-input-group:target,.slct-hrs .e-input-group:active,.slct-hrs .e-input-group:visited
{
    border: none !important;
}

.slct-hrs .e-input-focus input
{
    border-color:  $palette-color5 !important;
}
.slct-hrs .e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
  
    color:  $palette-color5 !important;
}
.cls-span{
    font-size: 16px
}
// .cls-nav-span{
//     font-size: 14px
// }
.cls-sidebar{
    border: 1px solid #eee;
    box-shadow: 2px 0px 3px #eee;
    margin-left: 4px;
   
}

.cls-toolbar
{
    background-color: $palette-color3 !important;
    color: $palette-color1 !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background
{
    background-color: $palette-color3 !important;
}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type
{
    padding-left: 24px !important
}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell
{
    padding: 5px !important;
}

.mat-expansion-panel{
    background-color: $palette-color3 !important;
}

.mat-drawer-inner-container{
    background-color: $palette-color3 !important;
}



  .menu-highlight:hover{
    color: #efb1ff; /* make this whatever you want */
    }
.is-active{
  color: yellow;
  font-size: 20px;
}

  .box:hover{
    border: 5px solid yellow;
    }
    .image-hover:hover{
      transform: rotateY(360deg);
    transition: all 0.8s ease-in-out 0s;
    }

    /* @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } } */

    .image-hover-zoom:hover{
      width: 60px;
      /* background-color: yellow; */
    }
    .image-hover-zoom{
      width: 50px;
      /* background-color: yellow; */
    }

    /* The emerging W3C standard
   that is currently Firefox-only */
/* * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
} */

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
} 
  

.sticky-container{
  padding:0px;
  margin:0px;
  position:fixed;
  right:-200px;
  top:200px;
  width:240px;
  z-index: 1100;
}
.sticky li{
  list-style-type:none;
  padding:0px;
  margin:0px 0px 2px 0px;
  -webkit-transition:all 0.25s ease-in-out;
  -moz-transition:all 0.25s ease-in-out;
  -o-transition:all 0.25s ease-in-out;
  transition:all 0.25s ease-in-out;
  cursor:pointer;
}
.sticky li:hover{
  margin-left:-155px;
}
.sticky li img{
  float:left;
  margin:0px 4px;
  margin-right:5px;
}
.sticky li p{
  padding-top:5px;
  margin:0px;
  line-height:36px;
  font-size:11px;
}
.sticky li p a{
  text-decoration:none;
  color:#2C3539;
}
.sticky li p a:hover{
  text-decoration:underline;
} 
.sticky-app{
  margin-top: 50px;
}
.cls-menu-panel{
    max-width: 1200px !important;
    width: 800px !important;
    max-height: unset !important;
    overflow: unset;
    height: max-content;
  
}


.cls-menu-panel-view{
    max-width: 1200px !important;
    width: 1200px !important;
    //max-height: unset !important;
   // overflow: unset;
    height: max-content;

}

.display-flex { display: flex; }

.flex-1 { flex: 1; }

.flex-2 { flex: 2; }

.flex-3 { flex: 3; }

.flex-4 { flex: 4; }

.flex-5 { flex: 5; }

.flex-6 { flex: 6; }

[class*="flex-"] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}

input:disabled {
  color: black;
}

select:disabled {
  color: black;
}

// Add in style.scss as we are overriding css of global mat-select class which is part of other module.
.mat-select-disabled .mat-select-value {
  color: black;
}

::ng-deep.mat-form-field-disabled .mat-form-field-underline 
{ 
background-image: linear-gradient( to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0.42) 33%, #c2c7cc 0 ) !important; 
background-size: 1px 100% !important;
 background-repeat: repeat-x !important; 
} 

.mat-input-element:disabled{
    color: black !important;
}
.mat-select-disabled .mat-select-value {
    color: black !important;
  }
//   .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
//     //color: grey !important;
//   }

.fa, .fab, .fal, .far, .fas {
    font-size: 16px !important;
}
.mat-expansion-indicator::after{
    color: #fff !important;
}
.cls-custom-btn{
    padding: 50%;
}
.card-body {
    padding: 10px;
}