*{ padding: 0;margin: 0;}
body{ font-family: 'Roboto', sans-serif; color: #000;}
h1,h2,h3,h4,h5,h5,h6{ color: #000;font-weight:300;}
p {color: #000;font-size: 16px;line-height: 1.6;text-align: justify; font-weight: 100;  }
a{text-decoration:none;color:#000;}
.clear_all{clear:both;}
#return-to-top {position: fixed;bottom: 20px;right: 20px;background: #000;width: 40px;border-radius:50%;height: 40px;display: block;text-decoration: none;display: none;-webkit-transition: all 0.3s linear;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease; transition: all 0.3s ease;z-index:999;cursor: pointer; z-index: 9999;-webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);-moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);}
#return-to-top i { color: #fff; margin: 0;position: relative;left: 14px;top: 12px;font-size: 19px;-webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;}
#return-to-top:hover {background: #fff;border:1px solid #000;}
#return-to-top:hover i {color: #000;top: 5px;	}
button:focus, input:focus, textarea:focus,a:focus {outline: 0;}
/*header*/
.header{width:98%;padding:5px 1%;position:absolute;z-index:12;}
.header_bx{   width: 33%;
    float: left;
    padding: 0px 0px;}
.new_head{    width: 100%;
    margin-top: 10px;}
.logos{text-align:left;padding:5px 0px;}
.logos img{width:100%;max-width:170px;} 
.soc_menu p{display:inline-block;}
.soc_menu ul{margin:0px;margin-top:25px;text-align:right;}
.soc_menu ul li{list-style:none;margin-right:10px;display:inline-block;    color: #fff;}
.soc_menu ul li a{text-decoration:none;}
.soc_menu ul li a img{width:30px;}
.soc_menu ul li:nth-child(1) {position:relative;top:-4px;}
.login_menu ul{margin:0px;margin-top:25px;text-align:right;}
.login_menu ul li{list-style:none;margin-right:10px;display:inline-block;}
.login_menu ul li a{text-decoration:none;    color: #fff;}
.login_menu ul li a img{width:30px;}
.login_menu ul li a span {position:relative;top:-6px;    color: #fff;}
/*main_content*/
.main_content{width:100%;padding:0px 0%;background-image: url(../assets/images/bg.png);   /*background: linear-gradient(to bottom, #42379f, #5d2ba0);*/  position: relative;
 background-position: center center;background-repeat: no-repeat;background-size: cover;    display: inline-block; }
/* .side_menu{width:20%;background: linear-gradient(to bottom, #000000, #081244);float:left;    height: 100vh;    padding-top: 125px; } */
.side_menu{width:20%;background: rgba(8, 18, 66, .2);float:left;    height: 100vh;    padding-top: 125px; }
.nav-container{padding:20px 0px;transition: all 0.3s linear;}
.nav{list-style-type:none;margin:0;padding:0;}
.nav-container li{padding:10px 0px;position:relative;margin-bottom:5px;}
.nav-container li a {text-decoration:none;display:block;opacity:0.5;color:#8a85ad;text-transform: capitalize;padding-left:20px;transition: all 0.3s linear;}
.nav-container li a img{width:50px;margin-right:15px;}
.nav-container .active a{color: #fff; opacity:1;outline:0;}
.nav-container li:not(.active):hover a{color: #eee;}
span[class ^= "icon"]{position:absolute;left:20px;font-size: 1.5em;transition: all 0.3s linear;}
@media only screen and (max-width : 860px){
}
@media only screen and (max-width : 480px){
.nav-container,.nav-container li a{ width:auto;padding-left: 0;text-align: center;}
span[class ^= "icon"]{ left:8px;}
}
span.text{position:relative;top:-20px;}
.main_content_inn{width:80%; height:auto;float:left;    padding-top: 100px;height: 100vh;overflow-y:scroll;}
.main_content_inn2{padding:20px 30px;}
.main_content_inn2 h3{color:#fff;margin:15px 0px;font-size:32px;}
.main_content_inn2 p{margin-bottom:15px;color:#fff;}
/*footer*/
/* .footer_bg{background: linear-gradient(to bottom, #42379f, #5d2ba0);} */
.footer{width:96%;padding:20px 2%;}
.footer_bx{width:25%;float:left;  }
.footer_bx_inn{padding:15px;}
.footer_bx_inn h3{margin:0px 0px 20px 0px;color:#ffffff;font-weight:500;}
.footer_bx_inn ul{margin:0px;}
.footer_bx_inn ul li{list-style:none;display:block;margin-bottom:10px;}
.footer_bx_inn  ul li a{text-decoration:none;color:#ffffff;font-size:15px;}
.footer_bx_inn p{color:#ffffff;font-size:15px;text-align:left;}
.copyrights{width:100%;padding:20px 0px 10px 0px;}
.copyrights p{color:#ffffff;font-size:15px;text-align:center;}
/*main_class*/
.main_class{ padding:30px 30px;display: table;padding-top:100px;}
.main_class_in_title{width:100%;}
.main_class_in_title h3{color:#fff;margin:10px 0px;text-align:center;font-size:30px;}
.main_class_inn2{width:100%;}
.main_class_inn2_left{width:30%;float:left; }
.main_class_inn2_right{width:70%;float:left; }
.main_class_inn2_left_inn{border:1.5px solid #fff;border-radius:20px;padding:20px 30px;}
.main_class_inn2_left_inn h3{text-align:center;color:#fff;font-size:20px;text-transform:uppercase;}
.main_class_inn2_left_inn ul{margin:20px 0px 0px 0px;text-align:center;}
.main_class_inn2_left_inn ul li{list-style:none;display:inline-block;margin-right:15px;color:#fff;}
.main_class_inn2_right_inn{padding:10px 30px;}
.main_class_inn2_right_inn p{color:#fff;} 
.main_class_inn3{width:100%;}
.class_pg_title{width:100%;}
.med_unlck_title2_new h3{font-size:26px;}
.class_pg_title_left,.class_pg_title_right{ display:inline-block; }
.class_pg_title_left h3{margin:0px;}
.class_pg_title_right{margin-left:20px;}
.class_pg_title_right2{width:100%;}
.class_pg_title_right2 .tab_container{margin-top:40px;}
.main_class_bxx a{text-decoration:none;}
.main_class_bxx{width:40%;float:left;border:1.5px solid #fff;border-radius:20px;padding:25px 8px;margin-bottom:30px;margin-right:12%;transition:.2s;}
.main_class_bxx:hover h3,.main_class_bxx:hover ul li{color:#fbff12;}
.main_class_bxx:hover{border:1.5px solid #fbff12;}
.main_class_bxx:nth-child(even) {margin-right:0%;}
.main_class_bxx h3{text-align:center;color:#fff;font-size:16px;text-transform:uppercase;}
.main_class_bxx ul{margin:20px 0px 0px 0px;text-align:center;}
.main_class_bxx ul li{list-style:none;display:inline-block;margin-right:15px;color:#fff;}
ul.tabs {margin: 0;padding: 0;float: left;list-style: none;height: 32px;
	border-bottom: 0px solid #333;width: 100%;position: relative;
    top: 8px;}
ul.tabs li {float: left;margin: 0;cursor: pointer;padding: 0px 21px;height: 31px;line-height: 31px;
border-top: 0px solid #333;border-left: 0px solid #333;border-bottom: 0px solid #333;background-color:transparent;color: #6a6fb8;
overflow: hidden;position: relative;    text-transform: capitalize;}
.tab_last { border-right:0px solid #333; }
ul.tabs li:hover {background-color:transparent;color: #fff;}
ul.tabs li.active {background-color:transparent;color: #fff;border-bottom: 0px solid #fff;display: block;}
.tab_container {border: 0px solid #333;border-top: none;clear: both;float: left;width: 100%;overflow: auto;}
.tab_content {padding: 20px 0px;display: none;}
.tab_drawer_heading { display: none; }
@media screen and (max-width: 480px) {
.tabs {display: none;}
.tab_drawer_heading {background-color:transparent;color: #fff;border-top:0px solid #333;margin: 0;padding: 5px 20px;display: block;cursor: pointer;
-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;
user-select: none;}
.d_active {background-color:transparent;color: #fff;}
}
.main_class_inn3{width:100%;margin:0px 0px;}
.main_class_inn3_bx{width:50%;float:left;margin:20px 0px 0px 0px;position: relative;}
.main_class_inn3_bx2{width:18%;} 
.main_class_inn3_bx i{color:#fff;}
.main_class_inn3_bx p{color:#fff;}
.main_class_inn3_bx span{color:#fff;}
.main_class_inn3_bx img{width:19px;margin-right:5px;position:absolute;left:0px;top:0px;}
.main_class_inn3_bx i,.main_class_inn3_bx p,.main_class_inn3_bx span{display:inline-block;font-size:14px;padding:0px 1px;}
.main_class_inn3_bx span{padding-left:25px;}
.main_class_inn4 table {border: 1px solid #000;border-collapse: collapse;margin:20px 0px;padding: 0;width: 100%;table-layout: fixed;color:#fff;border-radius:10px !important;}
.main_class_inn4 table caption {font-size: 1.5em;margin: .5em 0 .75em;}
.main_class_inn4 table tr {background-color:transparent;border:1px solid #fff;padding: .35em;border-radius:10px;}
.main_class_inn4 table th,
.main_class_inn4 table td {padding: .625em;text-align: center;}
.main_class_inn4 table th {font-size: .85em;letter-spacing: .1em;text-transform: uppercase;}
@media screen and (max-width: 600px) {
.main_class_inn4 table {border: 0;}
.main_class_inn4 table caption {font-size: 1.3em;} 
.main_class_inn4 table thead {border: none;clip: rect(0 0 0 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;} 
.main_class_inn4 table tr {border-bottom: 3px solid #ddd;display: block;margin-bottom: .625em;} 
.main_class_inn4 table td {border-bottom: 1px solid #ddd;display: block;font-size: .8em;text-align: right;}
.main_class_inn4 table td::before {content: attr(data-label);float: left;font-weight: bold;text-transform: uppercase;}
.main_class_inn4 table td:last-child {border-bottom: 0;}
}
.main_class_inn4.buttons_added {text-align: left;position: relative;white-space: nowrap;vertical-align: top; }
.main_class_inn4.buttons_added input {display: inline-block; margin: 0;vertical-align: top;box-shadow: none;}
.main_class_inn4.buttons_added .minus,
.main_class_inn4.buttons_added .plus {padding: 2px 10px 8px;height: 30px;color: #222;border:1px solid #dae2e6; background-color: #fff;cursor:pointer;}
.main_class_inn4.buttons_added .minus {border-right: 0; }
.main_class_inn4.buttons_added .plus {border-left: 0; }
.main_class_inn4.buttons_added .minus:hover,
.main_class_inn4.buttons_added .plus:hover {background: #dae2e6; }
.main_class_inn4 input::-webkit-outer-spin-button,
.main_class_inn4 input::-webkit-inner-spin-button {-webkit-appearance: none;-moz-appearance: none;margin: 0; }
.main_class_inn4.buttons_added .minus:focus,
.main_class_inn4.buttons_added .plus:focus {outline: none; }
.quck_cart_btns button{text-decoration:none;background:#dae2e6;border: 1px solid #dae2e6;color:#000;text-transform:uppercase;padding:10px;font-size:15px;transition:.2s;}
.quck_cart_btns,.quck_qty_span,.quck_wish_btn{display:inline-block;}
.quck_qty_span{margin-left:10px;}
.main_class_inn4 .input-text.qty {width: 35px;height: 28px;padding: 0 5px;text-align: center;    background-color: #dae2e6;border:1px solid #dae2e6;}
.main_class_inn4 .buttons_added input {display: inline-block;margin: 0;vertical-align: top;box-shadow: none;}
.main_class_inn4 .buttons_added .minus{
	padding: 6px 10px 8px;height: 30px;color: #222;border: 1px solid #dae2e6;background-color: #fff;
cursor: pointer;
margin: 0 -4px;
    border-top-left-radius: 10px;border-bottom-left-radius: 10px;
}
.main_class_inn4 .buttons_added .plus {
	padding: 6px 10px 8px;height: 30px;color: #222;border: 1px solid #dae2e6;background-color: #fff;
cursor: pointer;
margin: 0 -4px;  border-top-right-radius: 10px;border-bottom-right-radius: 10px;
}
.main_class_inn5 table tr {background-color: transparent;border: 0px solid #000;padding: .35em;border-radius: 10px;}
.main_class_inn5 table {border: 0px solid #000;}
.main_class_inn5{margin:20px 0px;}    
.main_class_inn5 h3{margin:15px 0px;color:#fff;font-size:19px;}
.main_class_inn5 input{width:90%;border:none;padding:10px;border-radius:5px;margin:0px 5% 0px 0px;}
.new_div{width:20%;float:left;margin-left:2%;}
 .medi_bx{width:16%;float:left;margin:0px 1.5%;margin-bottom:15px;border-radius:10px;position:relative;transition:.2s;}
 
.medi_bx img{width:100%;height:170px;object-fit:cover;border-radius:10px;} 
.med_lck{position:absolute;top:10px;right:10px;background:#000000a6;padding:2px;width:25px;height:25px;text-align:center;border-radius:100px;line-height:25px; }
.med_lck i{text-decoration:none;color:#fff;font-size:15px;}
.med_content{position:absolute;bottom:3px;left:0px;right:0px;background:#000000a6;padding:10px 0px;width:100%;height:auto;text-align:center;
 border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
.med_content p{text-decoration:none;color:#fff;font-size: 14px;
    text-transform: capitalize;text-align:center;margin:0px;}
	
.med_unlck_title{width:100%;margin-bottom:40px;    display: inline-block;}
.med_unlck_title1{width:3%;float:left;padding-top:5px;}
.med_unlck_title2{width:95%;float:left;}
.med_unlck_title a i{font-size:26px;color:#fff; }
.med_unlck_title h3{text-align:center;margin:0px;}
.med_unlck_title h3 i{padding-right:30px;}
.med_unlck_bx{width:100%;margin-bottom:40px;    display: inline-block;}
.med_unlck_bx2{width:45%;margin:auto;}
.med_unlck_bx2_new ul{margin:10px 0px;text-align:center;}
.med_unlck_bx2_new ul li{list-style:none;text-align:left;color:#fff;}
 
.med_unlck_bx2 ul li img{width:30px;position:relative;top:10px;left:0px;}
.container {width:100%;}
.checkbox {display: inline-block;padding: 10px 20px;-webkit-transform: translateZ(0);transform: translateZ(0);}
.checkbox label {cursor: pointer;padding-left: 0;}
.checkbox input[type=checkbox] {opacity: 0;position: absolute;margin: 0;z-index: -1;width: 0;height: 0;
  overflow: hidden;left: 0;pointer-events: none;}
.checkbox .checkbox-material {vertical-align: middle;position: relative;top: 0px;}
.checkbox .checkbox-material:before {position: absolute;left: 8px;top: 2px;content: "";background-color: #fff;
  height: 4px;width: 4px;border-radius: 100%;z-index: 1;opacity: 0;margin: 0;}
.checkbox .checkbox-material .check {position: relative;display: inline-block;width: 20px;height: 20px;border: 2px solid #fff;border-radius: 2px;overflow: hidden;
  z-index: 1;}
.checkbox .checkbox-material .check:before {position: absolute;content: "";-webkit-transform: rotate(45deg);transform: rotate(45deg);display: block;margin-top: -2px;
  margin-left: 8px;width: 0;height: 0;box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards ease-out;animation: checkbox-off 0.3s forwards ease-out;}
.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {opacity: 0.2;}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards ease-out;animation: checkbox-on 0.3s forwards ease-out;}
.checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {-webkit-animation: rippleOff 700ms forwards ease-out;
animation: rippleOff 700ms forwards ease-out;}
.checkbox input[type=checkbox]:checked + .checkbox-material:before {-webkit-animation: rippleOn 700ms forwards ease-out;animation: rippleOn 700ms forwards ease-out;}
.checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff 700ms forwards ease-out;animation: rippleOff 700ms forwards ease-out;}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 700ms forwards ease-out;animation: rippleOn 700ms forwards ease-out;}
.checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
.checkbox input[type=checkbox][disabled] + .circle {opacity: 0.5;}
.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: #fff;-webkit-transform: rotate(-45deg);transform: rotate(-45deg);}
.coloured .checkbox-material .check {color: #2FAEF8;}
.coloured .checkbox-material:before {
  background-color: #2FAEF8;}
.coloured input[type=checkbox]:checked + .checkbox-material .check {color: #fff;}
.med_unlck_bx2 .coloured i{color:#fff;padding-left:5px;font-style:normal;}
.container22{padding-left:0px;margin-top:40px;}
 .container22 ul{margin:0px;}
 .container22 ul li{list-style:none;display:inline-block;margin:0px 10px;}
 .container22 ul li:nth-child(1){width:150px;}
.radio {margin: 0.5rem;}
.radio input[type="radio"] {position: static;opacity: 0;}
.radio input[type="radio"] + .radio-label:before {content: '';background:transparent;border-radius: 100%;
  border: 2px solid #b4b4b4;display: inline-block;width: 1.4em;height: 1.4em;position: relative;top: -0.2em;margin-right: 1em;
  vertical-align: top;cursor: pointer;text-align: center;-webkit-transition: all 250ms ease;transition: all 250ms ease;}
.radio input[type="radio"]:checked + .radio-label:before {background-color: #fff;box-shadow: inset 0 0 0 8px #6627a1;}
.radio input[type="radio"]:focus + .radio-label:before {outline: none;}
.radio input[type="radio"]:disabled + .radio-label:before {box-shadow: inset 0 0 0 8px #6627a1;border-color:transparent;}
.radio input[type="radio"] + .radio-label:empty:before {margin-right: 0;}
.container22 span{color:#fff;margin-left:10px;}
.subs_btn {text-align:center;margin-top:20px;    margin-left: -80px;}
.subs_btn input[type=submit]{background: linear-gradient(to right, #7977e7, #aa4ad8);width:auto;border-radius:25px;padding:8px 25px;color:#fff;border:none;
text-transform:uppercase;font-size:18px;font-weight:100;cursor:pointer;}
.med_unlck_title2 .med_unlck_title2 h3{text-align:left;}
.player_med{width:100%;text-align:center;}
.player_med_new_img img{width:100%;max-width:600px;height:250px;object-fit:cover;border-radius:10px;} 
.player_med_new_img2{text-align:center;}
.player_med_new_img2 img{width:100%;max-width:600px;height:auto;object-fit:cover;border-radius:10px;}
.player_med ul{text-align:center;margin:20px 0px;}
.player_med ul li{list-style:none;color:#fff;margin:0px 10px;display:inline-block;cursor:pointer;font-weight:100;position:relative;width:30px;text-align:center;}
.med_unlck_title2 h3{margin:0px 0px 20px 0px} 
.player_med ul li img{width:30px;position:absolute;left:0px;right:0px;}
.player_med ul li:nth-child(1) img{width:19px;    top: 2px;}
.player_med ul li:nth-child(5) img{width:19px;    top: 2px;}
.player_med ul li:nth-child(2) img{     top: 2px;}
.player_med ul li:nth-child(4) img{    top: 2px;}
.player_med ul li:nth-child(3){width:50px;}
.player_med ul li:nth-child(3) img{width:50px;top:-15px;}
/**home**/
.main_home{width:100%;height:100vh;padding:0px 0px;overflow:hidden;position:relative;background-size: cover;}
/*.main_home:before {position: absolute;content: '';left: 0px;top: 0px;width: 100%;height: 100%;display: block;background-color: rgba(55, 39, 39, 0.68);}*/
.main_home_bx1{width:100%;}
.main_home_bx1 ul{margin:0px;padding:100px 120px;text-align:center;}
.main_home_bx1 ul li{list-style:none;display:inline-block;margin:10px 0px;}
.main_home_bx1 ul li a{text-decoration:none;}
.main_home_bx1 ul li a img{width:100%;max-width:160px;}
.main_home_bx1 ul li:nth-child(1) {float:left;}
.main_home_bx1 ul li:nth-child(2) {float:right;}
.main_home_bx2{width:100%;margin-top:60px;text-align:center;}
.main_home_bx2 h1{color:#fff;font-size: 52px;margin-bottom:40px;}
.main_home_bx2 h3{color:#fff;font-size: 34px;line-height:1.4;}
/*main_login*/
.main_login{width:100%;padding-top:30px;padding-bottom:60px;border-bottom:1px solid #a74dd9;background-image: url(../assets/images/bg.png);}
.login_logo{width:100%;text-align:center;margin:10px 0px;}
.login_logo img{width:100%;max-width:250px;}
.login_logo h3{color:#686db7;margin:15px 0px 25px 0px;font-weight:500;text-transform:uppercase;font-size:30px;}
.main_login_bx{width:30%;margin:auto;border:1px solid #a74dd9;border-radius:50px;padding:25px 35px;}
.main_login_form{width:100%;}
.main_login_form p{text-align:center;color:#686db7;font-weight:500;margin:-10px 0px 10px 0px;font-size:18px;}
.main_login_form ul{margin:0px;text-align:center;}
.main_login_form ul li{list-style:none;display:block;position:relative;}
.main_login_form ul li span{    position: absolute;background: #fff;color:#686db7;font-size:16px;padding: 3px;left: 25px;top: -12px;}
.main_login_form input{width: 90%;margin: auto;border: 1px solid #a74dd9;border-radius:6px;padding:20px 15px;margin-bottom: 25px;}
.main_login_form input[type="submit"]{width:auto;border-radius:25px;color:#fff;text-transform:uppercase;padding:15px 30px;
font-size:20px;background: linear-gradient(to right, #836fe5, #a84dd9);border:none;}
.main_login_form ul li h4,.main_login_form ul li a{display:inline-block;font-size:17px;}
.main_login_form ul li h4{color:#000;}
.main_login_form ul li a{color:#686db7;padding-left:5px;}
.my_profile_menu{width: 36%;
    margin: auto;}
.my_profile_menu ul{margin:50px 0px 30px 0px;text-align:center;}
.my_profile_menu ul li{width:auto;list-style:none;display:block; position:relative;margin-bottom:25px;border:1px solid #fff;border-radius:15px;padding:15px 20px;}
.my_profile_menu ul li a{text-decoration:none;color:#fff;text-transform:capitalize;}
.my_profile_menu ul li a img{width:20px; position: absolute; left: 18%; top: 14px;}
.my_profile_menu ul li a:after{content: '\eaca';position: absolute;right: 8%;border: 1px solid #fff;border-radius: 100px;top: 16px;
    color: #fff;font-family: "Icofont";}
.hist_title h3{color: #fff;margin: 15px 0px;font-size: 32px;position: relative;padding-left:20px;}	
.hist_title h3 a{color: #fff;text-decoration:none;}
.hist_title h3 i{border:1px solid #fff;border-radius:100px;padding:1px;    font-size: 16px;position: absolute;top: 10px;left: -20px;}	
.hist_table table {border: 0px solid #ccc;border-collapse: collapse;margin: 40px 0px 0px 0px ;color: #fff;padding: 0;width: 100%;table-layout: fixed;}
.hist_table table caption {font-size: 1.5em;margin: .5em 0 .75em;}
.hist_table table tr {border-bottom: 1px solid #fff;padding: .35em;}
.hist_table table th,
.hist_table table td {padding: 15px;text-align: left;}
.hist_table table th {font-size: .85em;letter-spacing: .1em;text-transform: uppercase;}
@media screen and (max-width: 600px) {
.hist_table table {border: 0;}
.hist_table  table caption {font-size: 1.3em;}
.hist_table table thead {border: none;clip: rect(0 0 0 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;}
.hist_table table tr {border-bottom: 3px solid #ddd;display: block;margin-bottom: .625em;}
.hist_table table td {border-bottom: 0px solid #ddd;display: block;font-size: .8em;text-align: left;}
.hist_table table td::before {content: attr(data-label);float: left;font-weight: bold;text-transform: uppercase;} 
.hist_table table td:last-child {border-bottom: 0;}
}
.hist_table table tr td span{color:#fff;display:block;}
.hist_table table tr td:nth-child(1) {width:12%;}
.hist_table table tr td:nth-child(2) {width:70%;}
.hist_table table tr td:nth-child(3) {width:18%;}
.hist_table table tr td ul{background:#fff;padding:0px;border-radius:10px;text-align: center;}
.hist_table table tr td ul li{list-style:none;color:#fff;padding:4px;font-size:12px;}
.hist_table table tr td ul li span{color:#000;font-size:12px;}
.hist_table table tr td ul li:nth-child(1){background:#707070;border-top-right-radius: 10px;border-top-left-radius: 10px;}
.hist_table table tr td ul li p{color:red;text-align:center;margin:0px;font-size:15px;}